import "./assets/css/style.css";
import "./assets/css/transitions.css";
import "./assets/css/utils.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueClickAway from "vue3-click-away";
import VueGtag from "vue-gtag-next";
import JsonViewer from "vue3-json-viewer";
import Maska from "maska";
import { getGoogleAnalyticsCode } from "./utils/functions";
// import { VTooltip } from "v-tooltip";
//import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);

const gaCode = getGoogleAnalyticsCode();

const app = createApp(App);
app
  .use(JsonViewer)
  .use(router)
  .use(VueClickAway)
  .component("fa", FontAwesomeIcon)
  .use(VueGtag, {
    property: { id: gaCode },
  })
  .use(Maska)
  .mount("#app");
