<template>
  <div
    id="root"
    class="w-screen min-h-screen flex font-primary relative text-gray-800 bg-white"
  >
    <router-view />
  </div>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}
</style>
